export const PLACES = [{
    id: 2,
	title: "Долгопрудный",
    streets: [
        'Улица Академика Лаврентьева',
        'Береговая улица',
        'Береговой проезд',
        'Вербная улица',
        'Виноградная улица',
        'Улица Виноградова',
        'Бульвар Воздухоплавателей',
        'Восточная улица',
        'Гаражный проезд',
        'Гранитная улица',
        'Гранитный тупик',
        'ГСК-1',
        'ГСК-16-1/1',
        'ГСК-16-1/2',
        'ГСК-20',
        'Дирижабельная улица',
        'Дорожный проезд',
        'Дмитровское шоссе',
        'Улица Железнякова',
        'Улица Жуковского',
        'Заводская улица',
        'Зеленая улица',
        'Бульвар имени Умберто Нобиле',
        'Институтский переулок',
        'Колхозная улица',
        'Комсомольская улица',
        'Корабельная улица',
        'Бульвар космонавта Сереброва А.А.',
        'Летная улица',
        'Деревня Лихачево',
        'Лихачевский проезд',
        'Лихачевский проспект',
        'Лихачевское шоссе',
        'Улица Маяковского',
        'Улица Менделеева',
        'Молодежная улица',
        'Московская улица',
        'Московское шоссе',
        'Набережная улица',
        'Нагорная улица',
        'Научный переулок',
        'Новый бульвар',
        'Октябрьская улица',
        'Осенняя улица',
        'Улица Павлова',
        'Парковая улица',
        'Парусный бульвар',
        'Проспект Пацаева',
        'Первомайская улица',
        'Пионерский переулок',
        'Территория Промышленно-коммунальная зона',
        'Промышленный проезд',
        'Проспект Ракетостроителей',
        'Речная улица',
        'Северная улица',
        'Площадь Собина',
        'Советская улица',
        'Спортивная улица',
        'Старое Дмитровское шоссе',
        'Проезд Строителей',
        'Театральная улица',
        'Транспортный проезд',
        'Флотская улица',
        'Центральная улица',
        'Улица Циолковского',
        'Школьная улица',
        'Южная улица',
        'Улица Якова Гунина',
        'Якорная улица',
        '1-ый Вербный проезд',
        '1-я Советская улица',
        '1-я Станционная улица',
        '1-я Школьная улица',
        '2-ой Вербный проезд',
        '3-ий Вербный проезд',
        '4-ый Вербный переезд',
        '5-ый Вербный проезд',
        '6-ой Вербный проезд',
    ],
}, {
    id: 6,
    title: "Лобня",
    streets: [
        'Авиационная улица',
        'Аэропортовская улица',
        'Батарейная улица',
        'Березовая улица',
        'Улица Борисова',
        'Улица Братьев Улюшкиных',
        'Улица Брянско-Пролетарской Дивизии',
        'Букинское шоссе',
        'Улица Булычева',
        'Верхняя улица',
        'Взлетная улица',
        'Вознесенский переулок',
        'Вокзальная улица',
        'Восточная улица',
        'Улица Гагарина',
        'Улица Гайдара',
        'Улица Геологов',
        'Улица Горка',
        'Улица Горки Киовские',
        'Улица Горки Киовские хутор',
        'Улица Горького',
        'Дачная улица',
        'Улица Декабристов',
        'Деповская улица',
        'Дербеневская улица',
        'Дмитровская улица',
        'Дмитровский 1-й проезд',
        'Дмитровский 2-й проезд',
        'Переулок Добров',
        'Дорожная улица',
        'Дорожный проезд',
        'Дорожный тупик',
        'Улица Дружбы',
        'Улица Дубовая Роща',
        'Улица Есенина',
        'Железнодорожная улица',
        'Улица Женевского',
        'Улица Жирохова',
        'Улица Жуковского',
        'Заводская улица',
        'Западная улица',
        'Запрудная улица',
        'Заречная улица',
        'Зеленая улица',
        'Улица Иванищенко',
        'Индустриальная улица',
        'Улица Калинина',
        'Улица Катюшки',
        'Керамическая улица',
        'Улица Киово',
        'Киово-Парковая улица',
        'Киово-Спасская улица',
        'Киовский переулок',
        'Улица Кирова',
        'Кленовая улица',
        'Улица Колычева',
        'Кольцевая улица',
        'Улица Комиссара Агапова',
        'Коммунистическая улица',
        'Комсомольская улица',
        'Улица Космонавтов',
        'Красная улица',
        'Красноармейская улица',
        'Краснопольский переулок',
        'Краснополянская улица',
        'Краснополянский проезд',
        'Краснополянский тупик',
        'Краснополянское шоссе',
        'Улица Крупской',
        'Лазурная улица',
        'Улица Лейтенанта Бойко',
        'Улица Ленина',
        'Улица Лермонтова',
        'Лесная улица',
        'Липовая улица',
        'Улица Лобненский Бульвар',
        'Локомотивная улица',
        'Луговая улица',
        'Малая улица',
        'Малая Набережная улица',
        'Улица Маяковского',
        'Мирная улица',
        'Улица Можайского',
        'Молодежная улица',
        'Улица Монтажников',
        'Московская улица',
        'Набережная улица',
        'Научный городок',
        'Улица Некрасова',
        'Улица Нестериха',
        'Оборонная улица',
        'Огородная улица',
        'Огородный тупик',
        'Озерная улица',
        'Окружная улица',
        'Октябрьская улица',
        'Ольховая улица',
        'Офицерская улица',
        'Офицерский 1-й переулок',
        'Офицерский 2-й переулок',
        'Офицерский 3-й переулок',
        'Улица Павлика Морозова',
        'Улица Панфилова',
        'Парковая улица',
        'Партизанская улица',
        'Партизанский тупик',
        'Первая улица',
        'Первомайская улица',
        'Пионерская улица',
        'Пионерская 1-я улица',
        'Пионерская 2-я улица',
        'Улица Победы',
        'Подмосковная улица',
        'Полевая улица',
        'Полевой 1-й переулок',
        'Полевой 2-й переулок',
        'Преображенский переулок',
        'Промышленная улица',
        'Территория ПСК Ягодка',
        'Улица Пушкина',
        'Рабочая улица',
        'Речная улица',
        'Речной проезд',
        'Речной 1-й переулок',
        'Речной 2-й переулок',
        'Рогачевское шоссе',
        'Рябиновая улица',
        'Садовая улица',
        'Светлая улица',
        'Светлый переулок',
        'Свободный проезд',
        'Сельский переулок',
        'Силикатная улица',
        'Советская улица',
        'Солнечная улица',
        'Солнечногорская улица',
        'Сосновая улица',
        'Спортивная улица',
        'Степная улица',
        'Улица Строителей',
        'Сумароковская улица',
        'Текстильная улица',
        'Тихая улица',
        'Тихий тупик',
        'Тихий 1-й переулок',
        'Тихий 2-й переулок',
        'Троицкий переулок',
        'Трудовая улица',
        'Улица Туголукова',
        'Улица Тургенева',
        'Урожайная улица',
        'Фабричная улица',
        'Фестивальная улица',
        'Физкультурная улица',
        'Цветочная улица',
        'Центральная улица',
        'Улица Циолковского',
        'Улица Чайковского',
        'Улица Чапаева',
        'Улица Чехова',
        'Улица Чкалова',
        'Проезд Шадунца',
        'Шереметьевское шоссе',
        'Школьная улица',
        'Юбилейная улица',
        'Улица Юности',
        'Улица 9 Квартал',
        '9 Мая улица',
        'Улица 40 лет Октября',
    ],
}, {
	id: 3,
	title: "МФТИ: МК",
    streets: [
        'Авиационная улица',
        'Улица Академика Глушко',
        'Улица Академика Грушина',
        'Аптечная улица',
        'Улица Бабакина',
        'Улица Березовая Аллея',
        'Библиотечная улица',
        'Улица Бурденко',
        'Улица Бутаково',
        'Улица Ватутина',
        'Вашутинское шоссе',
        'Улица Германа Титова',
        'Улица Глинки',
        'Улица Гоголя',
        'Улица Горшина',
        'Улица Дружбы',
        'Железнодорожная улица',
        'Улица Жуковского',
        'Улица З.Космодемьянской',
        'Заводская улица',
        'Зеленая улица',
        'Улица Имени К. И. Вороницына',
        'Улица Калинина',
        'Улица Кирова',
        'Кольцевая улица',
        'Коммунальный проезд',
        'Коммунистическая улица',
        'Красноармейская улица',
        'Улица Крылова',
        'Улица Кудрявцева',
        'Куркинское шоссе',
        'Улица Лавочкина',
        'Ленинградская улица',
        'Ленинградское шоссе',
        'Ленинский проспект',
        'Летняя улица',
        'Лихачевское шоссе',
        'Лобненское шоссе',
        'Улица М.Рубцовой',
        'Манежный проезд',
        'Улица Марины Расковой',
        'Улица Машинцева',
        'Машкинское шоссе',
        'Улица Маяковского',
        'Международное шоссе',
        'Проспект Мельникова',
        'Улица Мельникова',
        'Местный проезд',
        'Проспект Мира',
        'Улица Мичурина',
        'МКАД 74-й километр',
        'Молодежная улица',
        'Молодежный проезд',
        'Улица Москвина',
        'Московская улица',
        'Нагорное шоссе',
        'Улица Нахимова',
        'Бульвар Николая Комиссарова',
        'Новое шоссе',
        'Новокуркинское шоссе',
        'Новосходненское шоссе',
        'Озерная улица',
        'Улица Опанасенко',
        'Улица Осипенко',
        'Улица Павлова',
        'Улица Панфилова',
        'Парковая улица',
        'Первомайская улица',
        'Пионерская улица',
        'Улица Победы',
        'Улица Пожарского',
        'Привокзальная площадь',
        'Пролетарская улица',
        'Территория Промышленно-коммунальная зона',
        'Улица Путилково',
        'Улица Р.Люксембург',
        'Рабочая улица',
        'Улица Репина',
        'Улица Родионова',
        'Северный проезд',
        'Совхозная улица',
        'Союзная улица',
        'Спартаковская улица',
        'Улица Станиславского',
        'Улица Строителей',
        'Транспортный проезд',
        'Улица Чапаева',
        'Улица Чернышевского',
        'Улица Чкалова',
        'Абонентский ящик Шереметьево-2',
        'Шереметьевское шоссе',
        'Улица Энгельса',
        'Юбилейный проспект',
        'Улица Юннатов',
        '1-й Северный проезд',
        'Улица 2-й Северный проезд',
        'Улица 8 Марта',
        'Улица 8 Микрорайон',
        '9 Мая улица',
    ],
}, {
	id: 1,
	title: "МФТИ",
    streets: [
    ],
},
];

export const MIPT = [
    { id: 1, title: "1-ка" },
    { id: 2, title: "2-ка" },
    { id: 3, title: "3-ка" },
    { id: 4, title: "4-ка" },
    { id: 5, title: "6-ка" },
    { id: 6, title: "7-ка" },
    { id: 7, title: "8-ка" },
    { id: 8, title: "9-ка" },
    { id: 9, title: "10-ка" },
    { id: 10, title: "11-ка" },
    { id: 11, title: "12-ка" },
    { id: 12, title: "профилак" },
    { id: 13, title: "АК" },
    { id: 14, title: "ГК" },
    { id: 15, title: "НК" },
    { id: 16, title: "ЛК" },
    { id: 17, title: "РТ" },
    { id: 18, title: "КПМ" },
    { id: 19, title: "БиоФарм" },
    { id: 20, title: "Физтех.Арктика" },
    { id: 21, title: "Физтех.Цифра" }, 
    { id: 22, title: "Первомайская 7" },
];

export const points = [

    {
        x: 55.924794,
        y: 37.505166,
        address: "Промышленный проезд, д 14, 2 этаж",
    },
    {
        x: 55.929182,
        y: 37.520653,
        address: "Первомайская ул, д.5, 2 этаж ГК<br>Кофейня, переход НК-ГК",
    },
    {
        x: 55.930660,
        y: 37.520572,
        address: "Первомайская ул, д.7А, 1 этаж",
    },
    {
        x: 55.929787,
        y: 37.515766,
        address: "Институтский пер, д.9, стр7, -1 этаж",
    },
    {
        x: 55.922530,
        y: 37.515587,
        address: "Лихачевский проезд, д. 2 к.1, 1 этаж",
    },
    {
        x: 55.905679,
        y: 37.544432,
        address: "Дмитровское шоссе, 118к1, помещ. 1.16, 1 этаж",
    },
    {
        x: 55.929444,
        y: 37.518434,
        address: "Институтский пер, д. 9, стр. 3, ГК, 2 этаж",
    },
    {
        x: 55.928305,
        y: 37.518111,
        address: "Научный переулок, 4, -1 этаж Арктика<br>Кофейня, 2 этаж",
    },
    {
        x: 55.880828,
        y: 37.423437,
        address: "Химки, ул Бабакина, д 5А, -1 этаж",
    },
    {
        x: 55.947227,
        y: 37.505400,
        address: "пр-кт Пацаева, д 7, к 1, 2 этаж",
    },
    {
        x: 55.921410,
        y: 37.526816,
        address: "Лихачёвский проезд, 5Б, 1 этаж",
    },
    {
        x: 55.922076,
        y: 37.513529,
        address: "проезд Лихачевский, д 6 стр 1, 1 этаж",
    },
    {
        x: 55.922383,
        y: 37.514275,
        address: "проезд Лихачевский, д. 4, с.1, 1 этаж",
    },
    {
        x: 55.928895,
        y: 37.521498,
        address: "ул Первомайская, д 3, 2 этаж",
    },
    {
        x: 55.947137,
        y: 37.488332,
        address: "ул.Гранитная д.6, -1 этаж",
    },
]

export const checkPhone = (phone) => ((phone?.match(/[0-9]/g) || [])?.length === 11);

export function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

export const CAPTCHA_SITE_KEY = "6LeKYAgUAAAAAI9R7XrZzHK8-BoERbRfgQ7ktLzX";

